/*
 * CONFIG file that IS NOT in gitignore, so DON'T put sensitive data here!!!
 */

const dev = {
  backend: {
    API_URL: 'http://localhost:3002/api',
  },
  adServer: {
    url: 'https://oglasi.eklata.com',
  },
}

const test = {
  backend: {
    API_URL: 'https://staging.vukusic.net/api',
  },
  adServer: {
    url: 'https://oglasi.eklata.com',
  },
}

const prod = {
  backend: {
    API_URL: 'https://izlet.hr/api',
  },
  adServer: {
    url: 'https://oglasi.eklata.com',
  },
}

let config
const stage = process.env.REACT_APP_STAGE
if (stage === 'dev') config = dev
if (stage === 'test') config = test
if (stage === 'prod') config = prod

//shared config items:

config.adServer.adZones = {
  c_0: { id: 3, n: 'ac9e50b1', ar: '936/180' }, //Povijest/Kultura
  c_2: { id: 4, n: 'adae27f8', ar: '936/180' }, //Priroda
  c_3: { id: 5, n: 'a16e9663', ar: '936/180' }, //Sport
  c_1: { id: 6, n: 'afae364c', ar: '936/180' }, //Umjetnost
  c_4: { id: 7, n: 'a71d1f3a', ar: '936/180' }, //Zabava
  header_mob: { id: 2, n: 'a71d1f3a', ar: '936/180' }, //Header mobile
  header_desk: { id: 9, n: 'a6cceb56', ar: '1456/280' }, //Header desktop
}

config.pedagogicalCounties = {
  Austrija: 'pedagogicalPrice_AU',
  Slovenija: 'pedagogicalPrice_SLO',
}

config.guideCounties = {
  austrija: 'guidePrice_AU',
  slovenija: 'guidePrice_SLO',
}

config.lunchCounties = {
  austrija: 'lunchPrice_AU',
  slovenija: 'lunchPrice_SLO',
}

config.insuranceCounties = ['austrija', 'slovenija']

export default { ...config }
